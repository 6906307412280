import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import GreyHeader from "../../components/GreyHeader";
import Article from "../../components/Article";
import BreadcrumbList from "../../components/seo/BreadcrumbList";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import TipsCarousel from "../../components/tipsAndTricks/TipsCarousel";
import { TIPS_AND_TRICKS_LIST } from "../../constants/tipsAndTricksList";

const Index = () => {
    const key = "wasBeiImmobilienfinanzierungBeachten";
    const question = TIPS_AND_TRICKS_LIST[key].question;

    return (
        <Layout>
            <MetaComponents
                title="Was muss ich bei der Immobilienfinanzierung beachten? | miracl"
                description="Einen Kredit aufzunehmen, bedeutet nicht nur Geld zu leihen, das man danach zurückzahlt. Er ist mit vielen Nebenkosten verbunden um die notwendigen Prozesse abzuwickeln."
            />

            <GreyHeader title="Was muss ich bei der Immobilienfinanzierung beachten?" />

            <Article hasMarginTop hasColumns>
                <p>
                    Bevor es an die Finanzierung deiner Immobilie geht, musst du zuerst ein paar grundsätzliche Dinge
                    herausfinden. Stelle dir folgende Fragen:
                </p>

                <ul>
                    <li>
                        <a href={"/gut-zu-wissen/wie-viel-kredit-kann-ich-mir-leisten/"}>
                            <b>Wie viel kann ich mir überhaupt leisten?</b>
                        </a>
                    </li>
                    <li>
                        <a href={"/gut-zu-wissen/welche-moeglichkeiten-der-eigenmittel-gibt-es/"}>
                            <b>Über wieviel Eigenkapital verfüge ich?</b>
                        </a>
                    </li>
                    <li>
                        <a href={"/gut-zu-wissen/welche-nebenkosten-entstehen-bei-einem-kredit/"}>
                            <b>Habe ich alle Nebenkosten im Blick?</b>
                        </a>
                    </li>
                </ul>

                <br />

                {/*TODO: This text needs rework, to be more useful*/}

                <p>
                    Indem du eine genaue Haushaltsrechnung erstellst, dir über deine Mittel klar wirst und dich über
                    alle Nebenkosten informierst, hast du den Grundstein für die Suche nach deinem Kredit gelegt. Dein
                    Einkommen sollte hoch genug sein, um nur weniger als die Hälfte davon für deine zukünftige
                    Kreditrate ausgeben zu müssen.
                </p>

                <p>
                    Ein großer Eigenkapitalanteil erhöht außerdem deine Chance auf attraktive Konditionen. Lass dir also
                    durch den Kopf gehen, welche Optionen du hast (Sparbücher, Gold oder Silber, Kunst, Fonds, usw.).
                    Wenn es so weit ist und du weißt, wieviel du dir leisten kannst, kannst du mit der Suche nach dem
                    passenden Kredit beginnen.
                </p>
            </Article>

            <TipsCarousel ignoreId={key} title={"Andere Tips"} />

            <BreadcrumbList page={key}></BreadcrumbList>

            <ArticleStructuredData
                page={key}
                heading={question}
                description="Einen Kredit aufzunehmen, bedeutet nicht nur Geld zu leihen, das man danach zurückzahlt. Er ist mit vielen Nebenkosten verbunden um die notwendigen Prozesse abzuwickeln."
                datePublished="2022-09-12"
                dateModified="2022-09-12"
            />
        </Layout>
    );
};

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["header", "footer"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
